import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useFetcher, useSearchParams } from 'react-router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContainerHeader } from '../container/auth-container'
import { ErrorList } from '../error-list/error-list'
import { ProviderConnectionForm } from '~/components/auth-form/provider-form'
import { Button } from '~/components/button/button'
import { StatusButton } from '~/components/button/status-button'
import { CheckboxField, Field } from '~/components/forms/forms'
import { Link } from '~/components/internal-link/Internal-link'
import { type action } from '~/routes/auth/login'
import { useIsFetching } from '~/utils/misc'
import { loginFormSchema } from '~/types/auth'
import { useClientLang } from '~/utils/i18n.utils'
import { HoneypotInputs } from 'remix-utils/honeypot/react'

export function LoginForm({
	text,
	toggleLoginModal,
	handlePageChange,
}: {
	text?: string
	toggleLoginModal?: () => void
	handlePageChange?: (page: number) => void
}) {
	const fetcher = useFetcher<typeof action>()
	const isPending = useIsFetching(fetcher)

	const [searchParams] = useSearchParams()
	const lang = useClientLang().lngShort
	const redirectTo =
		searchParams.get('redirectTo') ||
		(handlePageChange ? undefined : `/${lang}`)
	const { t } = useTranslation()
	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(loginFormSchema),
		lastResult: fetcher?.data?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: loginFormSchema })
		},
		defaultValue: {
			redirectTo,
		},
		shouldRevalidate: 'onInput',
	})

	useEffect(() => {
		if (fetcher?.data?.result.status === 'success' && toggleLoginModal) {
			toggleLoginModal()
		}
	}, [fetcher?.data?.result.status, handlePageChange, toggleLoginModal])

	return (
		<>
			<AuthContainerHeader title={<>{t('action_login')}</>} text={text} />
			<fetcher.Form method="POST" action="/login" {...getFormProps(form)}>
				<HoneypotInputs />
				<input
					hidden
					readOnly
					name="loginModal"
					value={toggleLoginModal ? 'true' : 'false'}
				/>
				<Field
					labelProps={{ children: t('form_label_email') }}
					inputProps={{
						...getInputProps(fields.email, {
							type: 'email',
						}),
						autoComplete: 'email',
						autoFocus: true,
						className: 'lowercase',
					}}
					errors={fields.email.errors}
				/>
				<Field
					labelProps={{ children: t('form_label_password') }}
					inputProps={{
						...getInputProps(fields.password, { type: 'password' }),
						autoComplete: 'current-password',
					}}
					errors={fields.password.errors}
				/>
				<div className="flex items-center justify-between align-middle">
					<CheckboxField
						className="mt-2"
						labelProps={{
							htmlFor: fields.remember.id,
							children: t('form_label_remember_me'),
						}}
						buttonProps={{
							...getInputProps(fields.remember, { type: 'checkbox' }),
						}}
						errors={fields.remember.errors}
					/>
					<div>
						{handlePageChange ? (
							<button
								className="text-body-md underline ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
								onClick={() => handlePageChange(1)}
								type="button"
							>
								{t('misc_forgot_password')}
							</button>
						) : (
							<Link to="/forgot-password" className="text-body-md underline">
								{t('misc_forgot_password')}
							</Link>
						)}
					</div>
				</div>

				<input {...getInputProps(fields.redirectTo, { type: 'hidden' })} />
				<ErrorList errors={form.errors} id={form.errorId} />

				<div className="flex items-center justify-between gap-6 pt-3">
					<StatusButton
						className="w-full"
						status={isPending ? 'pending' : 'idle'}
						type="submit"
						disabled={isPending}
					>
						{isPending ? t('auth_logging_in') : t('action_login')}
					</StatusButton>
				</div>
			</fetcher.Form>

			<ProviderConnectionForm redirectTo={redirectTo} />

			<div className="flex items-center justify-center gap-2 pt-6">
				<span>{t('misc_new_here')}</span>
				{handlePageChange ? (
					<Button
						onClick={() => handlePageChange(2)}
						className="!p-0 hover:underline"
						variant="link"
						type="button"
					>
						{t('auth_create_account')}
					</Button>
				) : (
					<Link to="/signup" className="hover:underline">
						{t('auth_create_account')}
					</Link>
				)}
			</div>
		</>
	)
}
